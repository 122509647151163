import styled from "styled-components";
import { breakPoints } from "../Breakpoints";

const Map = () => {
  return (
    <GoogleMap
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.486969966265!2d-73.5731936!3d45.5002749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91a43dc74abd9%3A0x1978d8e8cfafdd42!2s1411%20Peel%20St%2C%20Montreal%2C%20QC%20H3A%201S5!5e0!3m2!1sen!2sca!4v1699041085007!5m2!1sen!2sca"
      width="350"
      height="400"
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Marine Website Map"
    ></GoogleMap>
  );
};

export default Map;

const GoogleMap = styled.iframe`
  width: 100vw;
  height: 10%;

  ${breakPoints.tablet} {
    height: 30vh;
  }
  ${breakPoints.smallDesktop} {
    width: 45vw;
    height: 50vh;
    border: 1px solid black;
  }
`;
