import styled from "styled-components";
import Hamburger from "./Hamburger";
import { useState } from "react";
import { Link } from "react-scroll";
import X from "./X";
import { breakPoints } from "../Breakpoints";
import { useTranslation } from "react-i18next";

const Nav = () => {
  const { t, i18n } = useTranslation();

  const lngs = {
    en: { nativeName: "EN" },
    fr: { nativeName: "FR" },
  };

  const [hasClicked, setHasClicked] = useState(false);

  const clickHandler = () => {
    setHasClicked(!hasClicked);
    if (window.innerWidth < 991) {
      if (!hasClicked) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  };

  return (
    <Wrapper>
      <MarineGroupWrap>
        {/* <div>
          <img src={logo} alt="logo" />
        </div> */}
      </MarineGroupWrap>
      <RightWrapper $click={hasClicked}>
        <div>
          <li>
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={700}
              onClick={clickHandler}
              style={{ color: "white" }}
            >
              {t("titles.about")}
            </Link>
          </li>
          <li>
            <Link
              to={"properties"}
              spy={true}
              smooth={true}
              offset={78}
              duration={700}
              onClick={clickHandler}
              style={{ color: "white" }}
            >
              {t("titles.properties")}
            </Link>
          </li>
          <li>
            <Link
              to="management"
              spy={true}
              smooth={true}
              offset={0}
              duration={700}
              onClick={clickHandler}
              style={{ color: "white" }}
            >
              {t("titles.management")}
            </Link>
          </li>
          <li>
            <Link
              to={"contact"}
              spy={true}
              smooth={true}
              offset={0}
              duration={700}
              onClick={clickHandler}
              style={{ color: "white" }}
            >
              {t("titles.contact")}
            </Link>
          </li>
          <LanguageWrap>
            {Object.keys(lngs).map((lng) => (
              <LanguageButton
                key={lng}
                type="submit"
                onClick={() => i18n.changeLanguage(lng)}
              >
                {lngs[lng].nativeName.toLowerCase() !== i18n.resolvedLanguage &&
                  lngs[lng].nativeName}
              </LanguageButton>
            ))}
          </LanguageWrap>
        </div>
      </RightWrapper>
      <HamWrap onClick={clickHandler}>
        <div>{!hasClicked ? <Hamburger /> : <X />}</div>
      </HamWrap>
    </Wrapper>
  );
};

export default Nav;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`;

const MarineGroupWrap = styled.div`
  div {
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 50%;
    ${breakPoints.tablet} {
      width: 100%;
    }
  }
`;

const RightWrapper = styled.ul`
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column-reverse;
  transition: background-color 0.1s ease-in-out;
  padding: 3rem;
  padding-bottom: 40%;
  background-color: ${(props) =>
    props.$click ? "rgba(0,0,0,0.7)" : "rgba(0,0,0,0)"};
  z-index: ${(props) => (props.$click ? "1" : "0")};

  li {
    display: ${(props) => (props.$click ? "flex" : "none")};
    justify-content: center;
    width: 100%;
    ${breakPoints.smallDesktop} {
      display: flex;
      width: fit-content;
      height: fit-content;
    }
  }

  a {
    font-size: 2.5rem;
    padding: 1.5rem 0;
    width: fit-content;
    text-decoration: none;
    ${breakPoints.tablet} {
      font-size: 3.5rem;
    }
    ${breakPoints.smallDesktop} {
      font-size: 2.3rem;
      padding: 3rem 0;
      text-decoration: underline transparent;
      transition: text-decoration 0.5s ease-in;

      cursor: pointer;
      &:hover {
        text-decoration-color: rgba(20, 38, 50, 255);
      }
    }
  }

  div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 75%;
      ${breakPoints.smallDesktop} {
        width: 51vw;
        flex-direction: row;
        height: 100%;
      }
    }
  }

  Link {
    color: white;
  }

  ${breakPoints.tablet} {
    padding-bottom: 0%;
  }
  ${breakPoints.smallDesktop} {
    flex-direction: row;
    justify-content: right;
    padding: 0;
    position: static;
    background-color: rgba(0, 0, 0, 0);
    z-index: 0;
  }
`;

const HamWrap = styled.div`
  display: block;
  z-index: 999;
  padding: 1rem;
  /* border: 1px solid yellow; */
  div {
    height: 100%;
    display: flex;
    align-items: center;
    ${breakPoints.tablet} {
      padding: 1.2rem;
    }
  }
  ${breakPoints.smallDesktop} {
    display: none;
  }
`;

const LanguageButton = styled.button`
  all: unset;
  color: white;
`;

const LanguageWrap = styled.li`
  ${breakPoints.tablet} {
    font-size: 2.5rem;
  }

  ${breakPoints.smallDesktop} {
    padding: 3.35rem 0 0;
    font-size: 2rem;
    width: fit-content;
    cursor: pointer;
  }
`;
