import styled from "styled-components";
import { RxTextAlignJustify } from "react-icons/rx";
import { breakPoints } from "../Breakpoints";

const Hamburger = () => {
  return (
    <>
      <Wrapper>
        <RxTextAlignJustify />
      </Wrapper>
    </>
  );
};

export default Hamburger;

const Wrapper = styled.div`
  font-size: 3rem;
  ${breakPoints.tablet} {
    font-size: 4.5rem;
  }
`;
