import styled from "styled-components";
import { LuX } from "react-icons/lu";
import { breakPoints } from "../Breakpoints";

const X = () => {
  return (
    <>
      <TheX>
        <LuX />
      </TheX>
    </>
  );
};

export default X;

const TheX = styled.div`
  height: 4rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: white;
  font-size: 3rem;
  ${breakPoints.tablet} {
    font-size: 4.5rem;
  }
`;
