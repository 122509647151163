import styled from "styled-components";
import { breakPoints } from "../Breakpoints";
import { useTranslation } from "react-i18next";

const HeadOffice = () => {
  const { t } = useTranslation();
  return (
    <AdressWrapper>
      <div>
        <h4>{t("headOffice.headOffice")}</h4>
        <p>{t("headOffice.marineAddress")}</p>
        <p>{t("headOffice.city")}</p>
        <p>H3A 1S5 </p>
        <p>T: 514-284-1000</p>
        <p>kmintzberg@marinegroup.ca</p>
      </div>
    </AdressWrapper>
  );
};

export default HeadOffice;

const AdressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    margin-bottom: 2%;
    text-decoration: underline;
    ${breakPoints.tablet} {
      font-size: 2.3rem;
    }
  }
  div {
    margin: 3%;
    ${breakPoints.tablet} {
      font-size: 2rem;
    }
    ${breakPoints.smallDesktop} {
      margin: 0;
      padding-top: 1%;
      font-size: 1.7rem;
    }
  }
`;
