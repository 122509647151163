import styled from "styled-components";
import Map from "../Components/Map";
import Form from "../Components/Form";
import Titles from "../Components/Titles";
import { breakPoints } from "../Breakpoints";
import HeadOffice from "../Components/HeadOffice";
import { useTranslation } from "react-i18next";

const Contact = ({ height }) => {
  const { t } = useTranslation();
  return (
    <Wrapper height={height} id="contact">
      <Titles>{t("titles.contact")}</Titles>
      <FormMapWrap>
        {/* <Form /> */}
        <MapWrap>
          <Map />
          <Line>
            <div></div>
          </Line>
          <div>{breakPoints.smallDesktop && <HeadOffice />}</div>
        </MapWrap>
      </FormMapWrap>
      <div>{!breakPoints.smallDesktop && <HeadOffice />}</div>
    </Wrapper>
  );
};

export default Contact;

const Wrapper = styled.div`
  background-color: rgba(20, 38, 50, 255);
  color: white;
  padding-bottom: 5%;
`;

const FormMapWrap = styled.div`
  ${breakPoints.smallDesktop} {
    display: flex;
    justify-content: center;
    padding: 0 1%;
  }
`;

const MapWrap = styled.div`
  margin-top: 3%;
  ${breakPoints.smallDesktop} {
    margin-top: 1.7%;
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin-right: 10%;
  }
`;

const Line = styled.div`
  display: none;
  color: white;
  ${breakPoints.smallDesktop} {
    display: none;
    /* width: 45%;
    margin: 6% 0;
    border: 1px solid white; */
  }
`;
