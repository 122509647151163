import styled from "styled-components";
import { breakPoints } from "../Breakpoints";
const People = (props) => {
  return (
    <>
      <ContentWrapper>
        <Image src={props.image} alt="sheldon" />
        <TextWrapper>
          <div>
            <h3>{props.name}</h3>
            <Role>{props.role}</Role>
          </div>

          <Bio>{props.bio}</Bio>
        </TextWrapper>
      </ContentWrapper>
    </>
  );
};

export default People;

const ContentWrapper = styled.div`
  padding-bottom: 5%;
  ${breakPoints.smallDesktop} {
    display: flex;

    /* left, right, bottom padding for section  */
    padding: 0 5% 5%;
  }
`;

const Image = styled.img`
  width: 90vw;
  margin: 0 5% 2%;
  ${breakPoints.tablet} {
    width: 40vw;
    margin: 1% 0 0 5%;
  }
  ${breakPoints.smallDesktop} {
    width: 20vw;
    margin: 0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 5%;
  ${breakPoints.smallDesktop} {
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 0 0 3%;
  }

  h3 {
    ${breakPoints.tablet} {
      font-size: 2.3rem;
    }
  }
`;

const Role = styled.div`
  border-bottom: 1px solid whitesmoke;
  ${breakPoints.tablet} {
    font-size: 2rem;
  }
  ${breakPoints.smallDesktop} {
    font-size: 1.7rem;
  }
`;

const Bio = styled.div`
  font-weight: bold;
  padding: 3% 0;
  ${breakPoints.tablet} {
    font-size: 2rem;
  }
  ${breakPoints.smallDesktop} {
    padding: 1% 0 0 0;
    font-weight: bold;
    font-size: 1.7rem;
  }
`;
