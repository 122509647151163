import styled from "styled-components";
import montreal_bg from "../assets/montreal_bg.jpg";
import Nav from "../Components/Nav";
import Hero from "../Components/Hero";

const HomePage = () => {
  return (
    <Wrapper>
      <NavWrapper>
        <Nav />
      </NavWrapper>
      <Hero />
    </Wrapper>
  );
};

export default HomePage;

const Wrapper = styled.div`
  height: 100vh;
  background-image: url(${montreal_bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;

const NavWrapper = styled.div``;
