import { useState, useRef } from "react";
import { styled } from "styled-components";
import emailjs from "@emailjs/browser";
import { breakPoints } from "../Breakpoints";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const [info, setInfo] = useState({
    name: "",
    email: "",
    phone: "",
    business: "",
    message: "",
  });
  const [sendState, setSendState] = useState(false);
  const form = useRef();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    emailjs
      .sendForm(
        "service_1fzoh94",
        "contact_template",
        form.current,
        "bjEgKhl_5Ch7cf7gw"
      )
      .then(
        (result) => {
          setSendState(true);
          setInfo({
            name: "",
            email: "",
            phone: "",
            business: "",
            message: "",
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Form onSubmit={handleSubmit} ref={form}>
      <ElementWrapper>
        <label htmlFor="name">
          {t("form.name")} <span>*</span>
        </label>
        <Input
          type="text"
          id="name"
          name="user_name"
          value={info.name}
          onChange={(ev) => {
            setInfo({ ...info, name: ev.target.value });
          }}
          required
        ></Input>
      </ElementWrapper>
      <ElementWrapper>
        <label htmlFor="email">
          {t("form.email")}
          <span>*</span>
        </label>
        <Input
          type="email"
          id="email"
          name="user_email"
          value={info.email}
          onChange={(ev) => {
            setInfo({ ...info, email: ev.target.value });
          }}
          required
        ></Input>
      </ElementWrapper>
      <ElementWrapper>
        <label htmlFor="phone">{t("form.phone")}</label>
        <Input
          type="phone"
          id="phone"
          name="user_phone"
          value={info.phone}
          onChange={(ev) => {
            setInfo({ ...info, phone: ev.target.value });
          }}
        ></Input>
      </ElementWrapper>
      <ElementWrapper>
        <label htmlFor="business">{t("form.business")}</label>
        <Input
          type="business"
          id="business"
          name="user_business"
          value={info.business}
          onChange={(ev) => {
            setInfo({ ...info, business: ev.target.value });
          }}
        ></Input>
      </ElementWrapper>
      <ElementWrapper className={"message"}>
        <label htmlFor="message">
          {t("form.message")}
          <span>*</span>
        </label>
        <InputMessage
          type="text"
          id="message"
          name="message"
          value={info.message}
          onChange={(ev) => {
            setInfo({ ...info, message: ev.target.value });
          }}
          required
        ></InputMessage>
      </ElementWrapper>
      <ButtonWrapper>
        <div>
          <Button type="submit" value={"Send"}>
            {sendState ? "Sent!" : t("form.button")}
          </Button>
        </div>
      </ButtonWrapper>
    </Form>
  );
};

export default Contact;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  font-size: 10vw;

  ${breakPoints.smallDesktop} {
    margin: 0;
    justify-content: space-between;
  }
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  ${breakPoints.smallDesktop} {
    align-items: unset;
    margin: 0;
  }

  label {
    width: 92.5%;
    font-weight: bold;
    font-size: 1.7rem;

    /* font-size: max(3.2vw, 2.5rem); */
    ${breakPoints.tablet} {
      font-size: 2.5rem;
    }
    ${breakPoints.smallDesktop} {
      font-size: 2.2rem;
      width: 30vw;
      padding-bottom: 2%;
    }
  }
`;

const Input = styled.input`
  width: 92.5%;
  min-height: 6rem;
  color: black;
  padding-left: 3%;

  font-weight: bold;
  font-size: 1.5rem;

  ${breakPoints.tablet} {
    font-size: 2.1rem;
  }
  ${breakPoints.smallDesktop} {
    width: 100%;
    border: 1px solid black;
    font-size: 2rem;
    margin-bottom: 5%;
  }
`;

const InputMessage = styled.textarea`
  width: 92.5%;
  max-width: 92.5%;
  min-height: 10rem;
  padding: 2% 3%;
  color: black;
  font-weight: bold;

  &::placeholder {
    font-size: 2.5rem;
    font-family: inherit;
    padding-left: 1rem;
  }
  ${breakPoints.smallDesktop} {
    max-width: 100%;
    width: 100%;
    margin-bottom: 5%;
    font-size: max(0.9vw, 1.8rem);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  div {
    width: 92.5%;
    display: flex;
  }
  ${breakPoints.smallDesktop} {
    justify-content: unset;
    margin: 0;
  }
`;

const Button = styled.button`
  all: unset;
  color: black;
  background-color: lightsteelblue;
  font-size: max(1.5rem, 1.5vw);
  font-weight: bold;
  min-width: 5rem;
  padding: 1.6rem;
  text-align: center;
  ${breakPoints.tablet} {
    font-size: max(3vw, 2.5rem);
  }
  ${breakPoints.smallDesktop} {
    width: fit-content;
    font-size: max(1.1vw, 1.9rem);
    cursor: pointer;
    white-space: nowrap;
  }
`;
