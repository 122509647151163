import * as Accordion from "@radix-ui/react-accordion";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { useState } from "react";
import { breakPoints } from "../Breakpoints";
import { SlArrowDown } from "react-icons/sl";
import { useTranslation } from "react-i18next";

const Accord = ({ children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <AccordionRoot type="single" collapsible>
      <Accordion.Item value="item-1">
        <Accordion.Header>
          <AccordionTrigger onClick={toggleAccordion}>
            <Header>{t("properties.accordion.button")}</Header>

            <StyledArrow aria-hidden $isopen={isOpen} />
          </AccordionTrigger>
        </Accordion.Header>
        <AccordionContent>{children}</AccordionContent>
      </Accordion.Item>
    </AccordionRoot>
  );
};

export default Accord;

const AccordionRoot = styled(Accordion.Root)`
  padding: 1% 0 7% 4%;
  margin: 0 2%;
  ${breakPoints.smallDesktop} {
    padding: 0;
    margin: 5% 0 0 0;

    /* margin: 0% 12% 3%; */
    /* margin: -8% 12% 10% 48%; */
  }
`;

const AccordionTrigger = styled(Accordion.Trigger)`
  all: unset;
  color: black;
  background-color: #f1dede;
  padding: 2%;
  font-size: max(1.5vw, 1.5rem);
  width: 20vw;
  display: flex;
  justify-content: space-between;
  ${breakPoints.smallDesktop} {
    padding: 2% 2.5%;
    width: 25%;
    margin-bottom: 2.5%;
  }
`;

const StyledArrow = styled(SlArrowDown)`
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  transform: ${(props) => (props.$isopen ? "rotate(180deg)" : "rotate(0deg)")};
  font-size: 1.75rem;
  padding-top: 3%;
  ${breakPoints.tablet} {
    font-size: 3rem;
  }
  ${breakPoints.smallDesktop} {
    font-size: 2.5rem;
    margin-left: 7%;
  }
`;

const slideDown = keyframes`
from { height: 0 }
 to { height: "var(--radix-accordion-content-height)" }`;

const slideUp = keyframes`
 from { height: "var(--radix-accordion-content-height)"}
 to { height: 0 }
 

 `;

const AccordionContent = styled(Accordion.Content)`
  /* below not working not sure why */
  overflow: hidden;
  &[data-state="open"] {
    animation: ${slideDown} ${300}ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state="closed"] {
    animation: ${slideUp} ${300}ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

const Header = styled.span`
  ${breakPoints.tablet} {
    font-size: max(2.5vw, 2.5rem);
  }
  ${breakPoints.smallDesktop} {
    font-size: max(1vw, 1rem);
  }
`;
