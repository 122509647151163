import styled from "styled-components";
import { breakPoints } from "../Breakpoints";

const Titles = ({ children }) => {
  return (
    <TitleWrap>
      <h2>{children}</h2>
    </TitleWrap>
  );
};

const TitleWrap = styled.div`
  width: 100vw;
  height: fit-content;
  background-color: #9da3b1;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    text-align: center;
    ${breakPoints.tablet} {
      font-size: 3.5rem;
    }
    ${breakPoints.smallDesktop} {
      font-size: 3.2rem;
    }
  }
  ${breakPoints.smallDesktop} {
    margin-bottom: 4%;
    padding-bottom: 1rem;
  }
`;

export default Titles;
