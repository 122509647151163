import styled from "styled-components";
import Titles from "../Components/Titles";
import sheldon from "../assets/sheldon.webp";
import { breakPoints } from "../Breakpoints";
import { useTranslation } from "react-i18next";
import People from "../Components/People";

const Management = () => {
  const { t } = useTranslation();
  return (
    <Wrapper id="management">
      <Titles>{t("titles.management")}</Titles>
      <People
        image={sheldon}
        name={"Sheldon Mintzberg"}
        role={t("management.sheldon.title")}
        bio={t("management.sheldon.bio")}
      ></People>
    </Wrapper>
  );
};

export default Management;

const Wrapper = styled.div`
  background-color: rgba(20, 38, 50, 255);
  width: 100vw;
`;
