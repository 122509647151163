import styled from "styled-components";
import { Link } from "react-scroll";
import { breakPoints } from "../Breakpoints";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <h1>{t("company.company")}</h1>
        <div>
          <p>{t("hero.slogan")}</p>
          <StyledLink
            to="about"
            spy={true}
            smooth={true}
            offset={0}
            duration={700}
          >
            {t("hero.button")}
          </StyledLink>
        </div>
      </Wrapper>
    </>
  );
};

export default Hero;

const Wrapper = styled.section`
  position: absolute;
  color: white;
  top: 7%;
  left: 7%;

  h1 {
    font-size: 2.5rem;
    ${breakPoints.tablet} {
      font-size: 3.5rem;
    }
    ${breakPoints.smallDesktop} {
      font-size: 2.5rem;
    }
  }

  p {
    width: 85%;
    font-weight: bold;
    ${breakPoints.tablet} {
      font-size: 2.2rem;
    }
    ${breakPoints.smallDesktop} {
      font-size: 2.3rem;
      padding: 3% 0;
      width: 130%;
      margin-bottom: 5%;
    }
  }

  div {
    ${breakPoints.smallDesktop} {
      padding-left: 30%;
      position: absolute;
      top: 45%;
    }
  }

  ${breakPoints.smallDesktop} {
    /* border: 1px solid yellow; */
    width: 35vw;
    height: 40vh;
    padding: 3rem 0 0 3.5%;
    position: none;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const StyledLink = styled(Link)`
  display: block;
  border: 1px solid white;
  margin-top: 4%;
  color: white;
  width: fit-content;
  padding: 2%;
  font-weight: bold;

  ${breakPoints.tablet} {
    font-size: 2.2rem;
    border: 2px solid white;
  }
  ${breakPoints.smallDesktop} {
    font-size: 2.3rem;
    padding: 2% 3%;
    &:hover {
      cursor: pointer;
      transition: background-color 0.5s ease-in-out;
      background-color: rgba(20, 38, 50, 255);
    }
  }
`;
