import HomePage from "./Pages/HomePage";
import About from "./Pages/About";
import Properties from "./Pages/Properties";
import Contact from "./Pages/Contact";
import styled from "styled-components";
import Footer from "./Components/Footer";
import Management from "./Pages/Managment";

const Layout = () => {
  return (
    <Container>
      <HomePage />
      <About />
      <Properties />
      <Management />
      <Contact />
      <Footer />
    </Container>
  );
};

export default Layout;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;
