import styled from "styled-components";
import peel from "../assets/peel.webp";
import Peel_night from "../assets/Peel_night.webp";
import Titles from "../Components/Titles";
import { breakPoints } from "../Breakpoints";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id="about">
      <div>
        <div>
          {breakPoints.smallDesktop && <Titles>{t("titles.about")}</Titles>}
        </div>

        <ContentWrapper>
          <ImageWrapper>
            <Peel src={peel} alt="Peel-Building " />
            <PeelNight src={Peel_night} alt="Peel-night" />
          </ImageWrapper>

          <div>
            {!breakPoints.smallDesktop && <Titles>{t("titles.about")}</Titles>}
          </div>

          <Line>
            <div></div>
          </Line>

          <TextWrapper>
            <Text>
              <p>{t("about.marine.part1")}</p>
              <br />
              <p>{t("about.marine.part2")}</p>
              <br />
              <p>{t("about.marine.part3")}</p>
              {/* The Marine Group is a leading asset and property management
              company with expertise in office, retail, and industrial
              properties. Based in Montreal and Toronto, we serve clients across
              Canada, offering personalized solutions to meet your unique real
              estate needs. Our team combines industry knowledge and innovative
              approaches to deliver exceptional property management and asset
              optimization services. We customize our offerings to align with
              your specific goals and objectives. Partner with The Marine Group
              for trusted, client-focused real estate services. */}
            </Text>
          </TextWrapper>
        </ContentWrapper>
      </div>
    </Wrapper>
  );
};

export default About;

const Wrapper = styled.div`
  background-color: rgba(20, 38, 50, 255);
  display: flex;
  flex-wrap: wrap;

  ${breakPoints.smallDesktop} {
    width: 100vw;
    padding-bottom: 2%;
  }
`;

const ContentWrapper = styled.div`
  ${breakPoints.smallDesktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  ${breakPoints.smallDesktop} {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5%;
  }
`;

const Peel = styled.img`
  /* min-width: 30rem; */
  width: 100%;
  ${breakPoints.smallDesktop} {
    width: 40%;
    border: 1px solid black;
  }
`;

const PeelNight = styled.img`
  display: none;
  ${breakPoints.smallDesktop} {
    display: block;
    width: 40%;
    border: 1px solid black;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${breakPoints.smallDesktop} {
    width: 80vw;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-top: 2%;
  }
`;

const Text = styled.div`
  color: white;
  font-weight: bold;
  width: 90vw;
  padding: 1% 0 2% 4%;
  ${breakPoints.tablet} {
    font-size: 2rem;
    padding: 1% 0 2%;
  }
  ${breakPoints.smallDesktop} {
    font-size: 1.7rem;
    width: 100%;
    line-height: 182%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    height: fit-content;
    padding: 1% 0 2%;
  }
`;

const Line = styled.div`
  display: none;
  color: white;
  ${breakPoints.smallDesktop} {
    display: block;
    width: 50%;
    margin: 2.5% 0 0;
    border: 0.5px solid white;
  }
`;
