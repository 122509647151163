import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          company: { company: "The Marine Group" },
          titles: {
            about: "About",
            properties: "Properties",
            management: "Management",
            contact: "Contact Us",
          },
          hero: {
            slogan: "Realize your property's full potential today",
            button: "Learn More",
          },
          about: {
            marine: {
              part1:
                " The Marine Group, a prominent asset and property management company with a focus on office, retail, and industrial properties, operates out of Montreal and Toronto. Their extensive services reach clients across Canada, providing personalized solutions to address distinctive real estate needs.",
              part2:
                "The team at The Marine Group, comprised of seasoned professionals, combines comprehensive industry knowledge with innovative approaches to deliver exceptional property management and asset optimization services. What sets them apart is their commitment to customization, tailoring their offerings precisely to align with clients' specific goals and objectives.",
              part3:
                "In the ever-evolving real estate market, clients can entrust The Marine Group to be their strategic partner, navigating challenges, and unlocking opportunities. Elevate the real estate experience with a partner devoted to ensuring the success of assets. Choose The Marine Group – where expertise meets innovation, and real estate goals become a shared mission.",
            },
          },
          properties: {
            buildings: {
              marine: "The Marine Building",
              addressMarine: "1411 Peel Street, Montreal, Quebec H3A 1S5",
              zotique: "202 St. Zotique West",
              zotAddress: "Montreal, Quebec H2V 4S9",
            },
            about: {
              marine: {
                part1:
                  "Explore urban sophistication at our 8-story commercial building on Peel and Ste. Catherine streets in downtown Montreal. Featuring ground-level retail spaces and offices above, this property seamlessly blends modernity with commercial vibrancy, presenting a prime investment opportunity in a central location.",
                part2:
                  "Connectivity is a key asset, with direct access to the underground city, metro system, and Réseau Express Métropolitain (REM). This accessibility caters to the diverse needs of residents and commuters, ensuring a steady flow of foot traffic and enhancing the value of this commercial property.",
                part3:
                  "As a gateway to major transportation routes, the building stands as a beacon of convenience for customers and clients, making it an attractive prospect for businesses seeking a central and accessible location. Beyond being a commercial space, this property embodies a lucrative investment opportunity at the nexus of Montreal's evolving urban development.",
              },
              zotique:
                " Located at the heart of the Mile Ex tech area, this building offers versatile workshop/loft spaces ranging from 1,860 to 12,000 square feet. Its strategic location makes it a key asset for businesses seeking space for warehousing, storage, and light manufacturing operations.",
            },
            accordion: {
              button: "Details",
              marine: {
                left: {
                  buildingClass: "Building Class",
                  built: "Year Built",
                  size: "Building Size",
                  storeys: "Number of Storeys",
                  elevators: "Elevators",
                  floorPlate: "Floor Plate",
                  walking: "Walking Score",
                  parking: "Parking",
                },
                right: {
                  storeys: "7 above, 3 below",
                  elevator: "3 Passenger",
                  parking: "Reserved 24hrs parking - 2 levels",
                },
              },
            },
          },
          management: {
            sheldon: {
              title: "Chairman, Chief Executive Officer and Founder",
              bio: "Sheldon A. Mintzberg is chairman, Chief Executive Officer and Founder of the Marine Group. His business acumen and real estate expertise have guided the development of the group. His strategic direction and forward thinking are the driving force of the companies.",
            },
          },
          form: {
            name: "Name",
            email: "Email",
            phone: "Phone",
            business: "Business/Orginisation",
            message: "Message",
            button: "Submit",
          },
          headOffice: {
            headOffice: "Head Office",
            marineAddress: "1411 Peel Street, suite 700",
            city: "Montreal, Quebec",
          },
        },
      },
      fr: {
        translation: {
          company: { company: "Le Groupe Marine" },
          titles: {
            about: "À propos",
            properties: "Propriétés",
            management: "Équipe de gestion",
            contact: "Contact",
          },
          hero: {
            slogan:
              "Réalisez le plein potentiel de votre propriété dès aujourd'hui.",
            button: "Plus d'informations",
          },
          about: {
            marine: {
              part1:
                "Le Groupe Marine, une entreprise de gestion d'actifs et de biens immobiliers de premier plan, axée sur les propriétés de bureau, de détail et industrielles, opère à Montréal et à Toronto. Leurs services étendus touchent des clients à travers le Canada, offrant des solutions personnalisées pour répondre aux besoins distincts en immobilier.",
              part2:
                "L'équipe du Groupe Marine, composée de professionnels chevronnés, associe une connaissance approfondie de l'industrie à des approches innovantes pour fournir des services exceptionnels de gestion immobilière et d'optimisation d'actifs. Ce qui les distingue, c'est leur engagement envers la personnalisation, adaptant précisément leurs offres pour correspondre aux objectifs spécifiques de leurs clients.",
              part3:
                "Dans le marché immobilier en constante évolution, les clients peuvent confier au Groupe Marine le rôle de partenaire stratégique, naviguant à travers les défis et exploitant les opportunités. Rehaussez l'expérience immobilière avec un partenaire dévoué à garantir le succès des actifs. Choisissez le Groupe Marine, là où l'expertise rencontre l'innovation, et où les objectifs immobiliers deviennent une mission partagée.",
            },

            zotique:
              "Located at the heart of the Mile Ex tech area, this building offers versatile workshop/loft spaces ranging from 1,860 to 12,000 square feet. Its strategic location makes it a key asset for businesses seeking space for warehousing, storage, and light manufacturing operations.",
          },
          properties: {
            buildings: {
              marine: "L'Édifice Marine",
              marineAddress: "1411, rue Peel, Montréal, Québec H3A 1S5",
              zotique: "202 St. Zotique Ouest",
              zotAddress: "Montréal, Québec H2V 4S9",
            },

            about: {
              marine: {
                part1:
                  "Explorez la sophistication urbaine dans notre immeuble commercial de 8 étages situé aux rues Peel et Ste. Catherine au centre-ville de Montréal. Offrant des espaces de vente au détail au niveau du sol et des bureaux à l'étage, cette propriété marie harmonieusement modernité et vitalité commerciale, présentant une opportunité d'investissement de premier plan dans un emplacement central.",
                part2:
                  "La connectivité est un atout majeur, avec un accès direct à la ville souterraine, au système de métro et au Réseau Express Métropolitain (REM). Cette accessibilité répond aux besoins diversifiés des résidents et des navetteurs, garantissant un flux constant de piétons et rehaussant la valeur de cette propriété commerciale.",
                part3:
                  "En tant que porte d'entrée vers les principales voies de transport, l'immeuble se présente comme un phare de commodité pour les clients, faisant de lui une perspective attrayante pour les entreprises recherchant un emplacement central et accessible. Au-delà d'être un espace commercial, cette propriété incarne une opportunité d'investissement lucrative au cœur du développement urbain évolutif de Montréal.",
              },
              zotique:
                "Situé au cœur de la zone technologique de Mile Ex, cet immeuble propose des espaces de travail/loft polyvalents d'une superficie allant de 1 860 à 12 000 pieds carrés. Sa localisation stratégique en fait un atout majeur pour les entreprises recherchant de l'espace pour l'entreposage, le stockage et des opérations de fabrication légère.",
            },
            accordion: {
              button: "Détails",
              marine: {
                left: {
                  buildingClass: "Catégorie de bâtiment",
                  built: "Année de construction",
                  size: "Taille du bâtiment",
                  storeys: "Nombre d'étages",
                  elevators: "Ascenseurs",
                  floorPlate: "Surface utile d'étage",
                  walking: "Score de marche",
                  parking: "Stationment",
                },
                right: {
                  storeys: "7 étages au-dessus, 3 en dessous",
                  elevator: "3 passagers",
                  parking: "Stationnement réservé 24h/24 - 2 niveaux",
                },
              },
            },
          },
          management: {
            sheldon: {
              title: "Président, Directeur Général et Fondateur",
              bio: "Sheldon A. Mintzberg est le président, directeur général et fondateur du Groupe Maritime. Son sens des affaires et son expertise en immobilier ont guidé le développement du groupe. Sa direction stratégique et sa vision avant-gardiste sont la force motrice des entreprises.",
            },
          },
          form: {
            name: "Nom",
            email: "Couriel",
            phone: "Téléphone",
            business: "Entreprise",
            message: "Message",
            button: "Soumettre",
          },
          headOffice: {
            headOffice: "Bureau principal",
            marineAddress: "1411, rue Peel, bureau 700",
            city: "Montréal, Québec",
          },
        },
      },
    },
  });

export default i18n;
