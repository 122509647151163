import twoOtwo from "../assets/202.jpeg";
import marine_bldg from "../assets/marine_bldg.webp";
import styled from "styled-components";
import Accord from "../Components/Accordion";
import Titles from "../Components/Titles";
import { breakPoints } from "../Breakpoints";
import { useTranslation } from "react-i18next";

const Properties = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id="properties">
      <Titles> {t("titles.properties")}</Titles>
      <SubTitleWrap>
        <h2>Commercial</h2>
      </SubTitleWrap>
      <CenteringWrap>
        <DesktopWrapper>
          <ColumnWrap>
            <Image src={marine_bldg} alt="1411" />
            <AccordDesktopWrap>
              <Accord>
                <AccordInnerDiv>
                  <div>
                    {t("properties.accordion.marine.left.buildingClass")}:
                    <span>Boutique A</span>
                  </div>
                  <div>
                    {t("properties.accordion.marine.left.built")}:
                    <span>1989</span>
                  </div>
                  <div>
                    {t("properties.accordion.marine.left.size")}:
                    <span>76,833 SF</span>
                  </div>
                  <div>
                    {t("properties.accordion.marine.left.storeys")}:
                    <span>
                      {t("properties.accordion.marine.right.storeys")}
                    </span>
                  </div>
                  <div>
                    {t("properties.accordion.marine.left.elevators")}:
                    <span>
                      {t("properties.accordion.marine.right.elevator")}
                    </span>
                  </div>
                  <div>
                    {t("properties.accordion.marine.left.floorPlate")}:
                    <span>10,000 SQ/FT</span>
                  </div>
                  <div>
                    {t("properties.accordion.marine.left.walking")}:
                    <span>100</span>
                  </div>
                  <div>
                    {t("properties.accordion.marine.left.parking")}:
                    <span>
                      {t("properties.accordion.marine.right.parking")}
                    </span>
                  </div>
                </AccordInnerDiv>
              </Accord>
            </AccordDesktopWrap>
          </ColumnWrap>
          <TextWrapper>
            <div>
              <SubHeader>{t("properties.buildings.marine")}</SubHeader>
              <div>{t("properties.buildings.addressMarine")}</div>
            </div>
            <div style={{ borderBottom: "1px solid white" }}></div>
            <Text>
              <p>{t("properties.about.marine.part1")}</p>
              <br />
              <p>{t("properties.about.marine.part2")}</p>
              <br />
              <p>{t("properties.about.marine.part3")}</p>
            </Text>
          </TextWrapper>
        </DesktopWrapper>
      </CenteringWrap>
      <MobileAccordWrap>
        <Accord>
          <AccordInnerDiv>
            <div>
              {t("properties.accordion.marine.left.buildingClass")}:
              <span>Boutique A</span>
            </div>
            <div>
              {t("properties.accordion.marine.left.built")}: <span>1989</span>
            </div>
            <div>
              {t("properties.accordion.marine.left.size")}:
              <span>76,833 SF</span>
            </div>
            <div>
              {t("properties.accordion.marine.left.storeys")}:
              <span>{t("properties.accordion.marine.right.storeys")}</span>
            </div>
            <div>
              {t("properties.accordion.marine.left.elevators")}:
              <span>{t("properties.accordion.marine.right.elevator")}</span>
            </div>
            <div>
              {t("properties.accordion.marine.left.floorPlate")}:
              <span>10,000 SQ/FT</span>
            </div>
            <div>
              {t("properties.accordion.marine.left.walking")}: <span>100</span>
            </div>
            <div>
              {t("properties.accordion.marine.left.parking")}:
              <span>{t("properties.accordion.marine.right.parking")}</span>
            </div>
          </AccordInnerDiv>
        </Accord>
      </MobileAccordWrap>

      <Line>
        <div></div>
      </Line>

      <SubTitleWrap>
        <h2>Industrial</h2>
      </SubTitleWrap>
      <CenteringWrap>
        <DesktopWrapper>
          <div>
            <Image src={twoOtwo} alt="202" />
            {/* <AccordDesktopWrap>
              <Accord></Accord>
            </AccordDesktopWrap> */}
          </div>
          <TextWrapper>
            <div>
              <SubHeader>{t("properties.buildings.zotique")}</SubHeader>
              <div>{t("properties.buildings.zotAddress")}</div>
            </div>
            <div style={{ borderBottom: "1px solid white" }}></div>
            <Text>
              <p>{t("properties.about.zotique")}</p>
            </Text>
          </TextWrapper>
        </DesktopWrapper>
      </CenteringWrap>
      {/* <MobileAccordWrap>
        <Accord></Accord>
      </MobileAccordWrap> */}
    </Wrapper>
  );
};

export default Properties;

const Wrapper = styled.div`
  width: 100vw;
  background-color: rgba(20, 38, 50, 255);
  padding: 5% 0;
`;

const AccordDesktopWrap = styled.div`
  display: none;
  ${breakPoints.smallDesktop} {
    display: block;
  }
`;

const MobileAccordWrap = styled.div`
  ${breakPoints.smallDesktop} {
    display: none;
  }
`;

const CenteringWrap = styled.div`
  ${breakPoints.smallDesktop} {
    display: flex;
    justify-content: center;
    width: 100%;

    /* border: 1px solid yellow; */
  }
`;

const ColumnWrap = styled.div`
  ${breakPoints.smallDesktop} {
    display: flex;
    flex-direction: column;
  }
`;

const DesktopWrapper = styled.div`
  ${breakPoints.smallDesktop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10%;
    width: 100%;

    /* left and right margin for section */
    margin: 0 5% 0;
  }
`;

const SubTitleWrap = styled.div`
  width: 100%;
  padding: 5% 5% 5% 6%;
  text-decoration: underline;
  font-size: 1.3rem;
  ${breakPoints.tablet} {
    font-size: 2rem;
  }
  ${breakPoints.smallDesktop} {
    margin: 0 5% 2%;
    padding: 0;
    font-weight: bold;
    width: 15%;
    font-size: 1.8rem;
  }
`;

const Line = styled.div`
  display: block;
  width: 50%;
  margin: 5% 25%;
  border: 0.5px solid white;
  ${breakPoints.smallDesktop} {
    margin: 4% 33% 4%;
    width: 33%;
  }
`;

const TextWrapper = styled.div`
  width: 90vw;
  padding: 1% 0 0% 4%;
  margin: 2%;
  p {
    font-weight: bold;
  }
  ${breakPoints.tablet} {
    font-size: 2rem;
  }
  ${breakPoints.smallDesktop} {
    font-size: 1.7rem;
    /* min-width: 50rem; */
    width: 40%;
    padding: 0;
    margin: 0;
    div {
      padding-bottom: 1.5%;
    }
  }
`;

const Text = styled.div`
  padding: 3% 0 1%;
  ${breakPoints.smallDesktop} {
    padding-top: 3.5%;
    line-height: 182%;
  }
`;

const SubHeader = styled.h3`
  ${breakPoints.tablet} {
    font-size: 2.3rem;
  }
`;

const Image = styled.img`
  width: 100vw;
  ${breakPoints.smallDesktop} {
    /* padding: 1% 0 0% 4%; */
    /* margin: 0% 0% 50% 0; */
    width: 40vw;
    min-width: 45rem;
    object-fit: contain;
    /* margin-top: 13.4%; */
    /* images gap to align with line */
    margin-top: 9rem;
  }
`;

const AccordInnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  font-weight: bold;
  div {
    display: flex;
    justify-content: space-between;
    padding: 3.5% 2%;
    border: 2px solid whitesmoke;
    margin: 2% 0;
    ${breakPoints.tablet} {
      font-size: 2.2rem;
    }
    ${breakPoints.smallDesktop} {
      padding: 1%;
      margin: 0.5% 0;
      width: 105%;
      font-size: 1.7rem;
    }
  }
`;
