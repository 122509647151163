import styled from "styled-components";
import { breakPoints } from "../Breakpoints";

const Footer = () => {
  return (
    <Wrapper>
      <SubWrap>
        <div>
          Website Designed and Developed by:{" "}
          <a href="https://sebbyhere.com/" alt="contact" target="_blank">
            sbf
          </a>
        </div>
      </SubWrap>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  width: 100vw;
  height: 7.5vh;
  background-color: #9da3b1;
`;

const SubWrap = styled.div`
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  a {
    color: white;
    cursor: pointer;
    &:hover {
      color: #ff69bf;
    }
  }
  ${breakPoints.smallDesktop} {
    font-size: 1.5rem;
  }
`;
